import { useState } from 'react';
import { Button, IconButton, useMediaQuery, useTheme, Drawer } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const Header = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const styles = {
    container: {
      display: 'flex',
      top: 0,
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#000',
      color: '#fff',
      padding: isMobile ? '0' : '15px 80px',
      position: 'relative',
      overflow: 'hidden',
      opacity: 1,
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
    },
    nav: {
      display: isMobile ? 'none' : 'flex',
      alignItems: 'center',
      gap: '20px',
    },
    navItem: {
      color: 'white',
      fontSize: '18px',
      textDecoration: 'none',
      cursor: 'pointer',
      padding: '0 10px',
      fontWeight: '400',
      whiteSpace: 'nowrap',
    },
    button: {
      background: '#93F059',
      color: '#000',
      padding: '5px 25px',
      // display: isMobile ? 'none' : 'block',
    },
    menuIcon: {
      display: isMobile ? 'block' : 'none',
      fontSize: '36px',
      color: '#fff',
      cursor: 'pointer',
    },
    drawerContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      gap: '10px',
      backgroundColor: '#191919',
      zIndex: 9999,
      position: 'relative',
      color: '#fff',
      width: '250px',
    },
    drawerNavItem: {
      color: 'white',
      fontSize: '18px',
      textDecoration: 'none',
      cursor: 'pointer',
      padding: '10px 0',
      fontWeight: '400',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.logo}>
        {/* <h1 style={{ fontWeight: 500 }}>Zeegens</h1> */}
        <img src='/images/zeegens-logo-black.png' style={{ width: 220 }} />
      </div>
      <div style={styles.nav}>
        <a style={{ textDecoration: 'none' }} href='#services'><p style={styles.navItem}>Services</p></a>
        <a style={{ textDecoration: 'none' }} href='#process'><p style={styles.navItem}>Process</p></a>
        {/* <a style={{ textDecoration: 'none' }} href='#portfolio'> <p style={styles.navItem}>Portfolio</p></a> */}
        <a style={{ textDecoration: 'none' }} href='#testimonials'> <p style={styles.navItem}>Testimonials</p></a>
        <a href='#contact' style={{ textDecoration: 'none' }}> <Button style={styles.button}>Contact</Button></a>
      </div>
      <IconButton style={styles.menuIcon} onClick={handleDrawerToggle}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          style: {
            zIndex: 9999,
            backgroundColor: '#191919',
            color: '#fff',
            width: '250px', // Adjust the width as needed
          },
        }}
      >
        <div style={styles.drawerContent}>
          <a style={{ textDecoration: 'none' }} href='#services'> <p style={styles.drawerNavItem}>Services</p></a>
          <a style={{ textDecoration: 'none' }} href='#process'> <p style={styles.drawerNavItem}>Process</p></a>
          {/* <a style={{ textDecoration: 'none' }} href='#portfolio'> <p style={styles.drawerNavItem}>Portfolio</p></a> */}
          <a style={{ textDecoration: 'none' }} href='#testimonials'>   <p style={styles.drawerNavItem}>Testimonials</p></a>
          <a href='#contact' style={{ textDecoration: 'none' }}><Button style={styles.button} onClick={handleDrawerToggle}>Contact</Button></a>
        </div>
      </Drawer>
    </div>
  );
}

export default Header;
