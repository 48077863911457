const HeroSection = () => {

    return (
        <>
            <section class="row heroSec" id="home">
                <div class="col-lg-12 heroDiv">
                    <p style={{ color: 'white', opacity: '80%' }}>PREMIUM WEB AND APP DEVELOPMENT SERVICES</p>
                    <center>
                        <h2 class="heroHeading" style={{ opacity: '100%', color: 'white' }}>Transform Your Vision into a Stunning Website or Mobile App
                            {/* <font class="gradientText">Zeegens
                            </font> */}
                            <br />
                            <font style={{ color: 'white', opacity: '60%' }}>Stress-Free Development</font>
                        </h2>
                        <p class="heroPara">
                            Let Zeegens take your business to the next level with fast, seamless web and app solutions.
                            From ideation to launch, we ensure a smooth process, delivering custom-crafted websites and apps that drive growth and exceed expectations.
                        </p>
                    </center>
                    <a href="#contact"><button class="csBtn" style={{ color: 'black' }}>Get Started Now</button></a>
                </div>
            </section>

        </>
    );
}

export default HeroSection;