import { FaCircleUser } from "react-icons/fa6";

const testimonialsData = [
    {
        name: "Abdul Aziz",
        image: "images/oredogps.png",
        feedback: "Choosing Zeegens Technologies was the best decision for my life. After working with many development companies, finally I find these guys, they delivered a cutting-edge solution tailored to our needs. Their creative approach and ongoing support set them apart from others in the industry. Highly recommend!",
        role: "Director - Oredo GPS Solutions",
        website: "https://oredogps.com/",
    },
    {
        name: "Durjyodhan Mishra",
        image: "images/durjyodhan.png",
        feedback: "Working with Zeegens Technologies was a game-changer for our business. Their team delivered a stunning mobile app with flawless functionality. The process was smooth, and their dedication to meeting our requirements exceeded expectations. Truly a fantastic development partner!",
        role: "Director - Nivishka Services",
        website: "https://nivishka.com/",
    },
    {
        name: "Hasham Usman",
        image: "images/hasham.jpg",
        feedback: `Our experience with Zeegens has been exceptional! They possess a remarkable ability to translate 
        our vision into a stunning website that exceeded our expectations. Their technical expertise, professionalism, 
        and dedication to delivering on time make them our top choice for any future projects.`,
        role: "Founder - BuzzFiling",
        website: "https://buzzfiling.com/",
    },
    {
        name: "Amir Hanif",
        image: "images/amir-hanif-1.jpg",
        feedback: `We are truly amazed by how Zeegens exceeded our expectations. Their team's exceptional talent 
        and unwavering dedication resulted in a website that not only beautifully represents our brand but also 
        delivers an unmatched user experience. It has become an integral part of our business, attracting new 
        customers and driving growth.`,
        role: "Founder & CEO - Micahguru Formations",
        website: "https://micahguru.com/",
    },
    {
        name: "Chetan KR",
        image: "images/chetan.png",
        feedback: "Zeegens Technologies delivered exceptional website development services. He guided me through every step, making the process seamless and efficient. His professionalism and expertise ensured outstanding results, all at a budget-friendly cost. I highly recommend Zeegens Technologies for their quality, dedication, and reliable service.",
        role: "",
        website: "",
    },
    // {
    //     name: "Dr. Anwar Syyad",
    //     image: "images/arshad-ahmad-new.png",
    //     feedback: "Zeegens Technologies provided exceptional service in developing our matrimonial platform. The team's technical expertise, creative approach, and commitment to deadlines were remarkable. We are extremely happier with the outcome, they come up. They're our go-to team for all future projects!",
    //     role: "Founder - DilSey",
    //     website: 'https://dilsey.in/',
    //     image: "images/hasham.jpg",
    // },
    {
        name: "Bijoy Mishra",
        image: "images/bijoy.png",
        feedback: "Our app was developed faster than expected, with a flawless user experience thanks to Zeegens Technologies. Their team is professional, knowledgeable, and always available to help. We’re thrilled with the results and look forward to working with them again!",
        role: "From - Nivishka PMS",
        website: "https://pms.nivishka.com/",
    },
    {
        name: "Prathik Korwar",
        image: "images/amir-hanif-1.jpg",
        feedback: `The website development for MilkPromise was exceptional. The team delivered a responsive and user-friendly platform tailored to our needs. Their technical expertise and attention to detail ensured a seamless and efficient development process. Highly recommend their services for professional website development!`,
        role: "From - MilkPromise",
        avatar: <FaCircleUser fontSize={35} style={{ marginRight: '3%', opacity: '70%' }} />,
        website: "https://milkpromise.com/",
    },
    {
        name: "Er. Arshad Ahmad",
        image: "images/arshad-ahmad-new.png",
        feedback: `Working with Zeegens was an absolute pleasure. From the initial consultation to the final launch, 
        their team's professionalism and creativity shone through. They took the time to understand our unique requirements 
        and crafted a seamless website that has positively impacted our online presence.`,
        role: "",
        website: "",
    },
];

const Testimonials = () => {

    return (
        <>
            <section className="row testiSec" id="testimonials" style={{ opacity: '100%', color: 'white' }}>
                <div className="row">
                    <center>
                        <h2 className="csHeading">
                            <span className="gradientText">Zeegens </span>Experience: What Our Clients Value Most
                        </h2>
                    </center>
                </div>
                {testimonialsData.map((testimonial, index) => (
                    <div className="col-lg-4 testiParentCol" key={index} style={{ position: 'relative', zIndex: 1 }}>
                        <div className="testiDiv">
                            <div className="testiCard">
                                {
                                    testimonial?.avatar ? (
                                        <>
                                            {testimonial?.avatar}
                                        </>
                                    ) : (
                                        <img src={testimonial.image} alt={testimonial.name} className="testiImg" />
                                    )
                                }
                                <p className="testiName">{testimonial.name}</p>
                            </div>
                            <p style={{ opacity: '80%', fontSize: 14 }}>
                                {testimonial?.feedback}
                            </p>
                            {testimonial?.role && (
                                <a href={testimonial.website} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                                    <p style={{ opacity: '60%', fontSize: 14 }}>{testimonial?.role}</p>
                                </a>
                            )}
                            <img src="images/5-star.svg" width="80" alt="5 stars" />
                        </div>
                    </div>
                ))}
            </section>
        </>
    );
}

export default Testimonials;
