const CTA = () => {

    return (
        <>

            <section class="row ctaSec" style={{ opacity: '100%', color: 'white' }}>
                <div class="col-lg-12 ctaDiv" style={{ padding: '4%' }}>
                    <p style={{ opacity: '80%' }}>CONATCT US NOW</p>
                    <h2 class="ctaHeading">
                        <font class="gradientText">Ready to make it happen?</font><br />Let's Zeegens Help You
                    </h2>
                    <p class="ctaPara">Ready to elevate your online presence? We’re passionate about helping businesses like yours succeed. Let’s turn your ideas into reality.
                    </p>
                    <a href="#contact"><button class="csBtn ctaBtn" style={{ color: 'black' }}>GET STARTED TODAY</button></a>
                </div>
            </section>
        </>
    );
}

export default CTA;
