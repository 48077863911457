import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";

export default function Thankyou() {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const navigate = useNavigate()

    const styles = {
        container: {
            background: 'black',
            color: 'white',
            padding: isDesktop ? '5% 10%' : '5% 0',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center'
        },
        heading: {
            opacity: '100%',
            color: 'white',
            fontWeight: 600,
            fontSize: 40,
            width: isDesktop ? '65%' : '90%',
            margin: '3% auto 0'
        }
    }

    return (
        <>
            <div style={styles.container}>
                <img src="https://oredomotor.com/images/success.svg" />
                <h3 style={styles.heading}>
                    Thank You! <br />
                    We have received your Request, our team will contact you shortly.
                </h3>
                <button
                    class="csBtn"
                    style={{ color: 'black', width: isDesktop ? 170 : 200, marginTop: '3%', }}
                    onClick={() => navigate('/')}>
                    Back to Home
                </button>
            </div>
        </>
    )
}