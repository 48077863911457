import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

const Footer = () => {

    return (
        <>
            <footer class="row footerSec" style={{ opacity: '100%', color: 'white' }}>

                <div class="row" id="mobile-footerSec-1">
                    <div class="row">
                        <div class="col-6">
                            <h4>Company</h4>
                            <ul class="footerItems">
                                <a href="#home">
                                    <li class="footerItem">Home</li>
                                </a>
                                <a href="#contact">
                                    <li class="footerItem">Contact</li>
                                </a>
                                <a href="#process">
                                    <li class="footerItem">How we work</li>
                                </a>
                            </ul>
                        </div>
                        <div class="col-6">
                            <h4>Services</h4>
                            <ul class="footerItems">
                                <a href="#services">
                                    <li class="footerItem">E-commerce Website</li>
                                </a>
                                <a href="#services">
                                    <li class="footerItem">SaaS Website</li>
                                </a>
                                <a href="#services">
                                    <li class="footerItem">Business Website</li>
                                </a>
                                <a href="#services">
                                    <li class="footerItem">Custom Web and App</li>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <h4>Legal</h4>
                            <ul class="footerItems">
                                <a href="#features">
                                    <li class="footerItem">Why choose us</li>
                                </a>
                                <a href="#testimonials">
                                    <li class="footerItem">Testimonials</li>
                                </a>
                            </ul>
                        </div>
                        <div class="col-6 footerSocialDiv">
                            <h4>Follow us</h4>
                            <a href="https://www.linkedin.com/company/zeegens/" target="_blank">
                                <FaLinkedin className="footerIcons" />
                            </a>
                        </div>
                    </div>
                </div>



                <div class="row footerSec-1" id="desktop-footerSec-1">
                    <div class="col-lg-3">
                        <h4>Company</h4>
                        <ul class="footerItems">
                            <a href="#home">
                                <li class="footerItem">Home</li>
                            </a>
                            <a href="#contact">
                                <li class="footerItem">Contact</li>
                            </a>
                            <a href="#process">
                                <li class="footerItem">How we work</li>
                            </a>
                        </ul>
                    </div>
                    <div class="col-lg-3">
                        <h4>Services</h4>
                        <ul class="footerItems">
                            <a href="#services">
                                <li class="footerItem">E-commerce Website</li>
                            </a>
                            <a href="#services">
                                <li class="footerItem">SaaS Website</li>
                            </a>
                            <a href="#services">
                                <li class="footerItem">Business Website</li>
                            </a>
                            <a href="#services">
                                <li class="footerItem">Custom Web and App</li>
                            </a>
                        </ul>
                    </div>
                    <div class="col-lg-3">
                        <h4>Navigation</h4>
                        <ul class="footerItems">
                            <a href="#features">
                                <li class="footerItem">Why choose us</li>
                            </a>
                            <a href="#testimonials">
                                <li class="footerItem">Testimonials</li>
                            </a>
                        </ul>
                    </div>
                    <div class="col-lg-3 footerSocialDiv">
                        <h4>Follow us</h4>
                        {/* <a href="https://www.twitter.com/Zeegenss/" target="_blank">
                            <FaTwitter className="footerIcons" />
                        </a> */}
                        <a href="https://www.linkedin.com/company/zeegens/" target="_blank">
                            <FaLinkedin className="footerIcons" />
                        </a>
                        {/* <a href="https://www.linkedin.com/company/Zeegenss/" target="_blank">
                            <FaInstagram className="footerIcons" />
                        </a> */}
                    </div>
                </div>
                <div class="row footerSec-2">
                    <hr class="hrFooter" />
                    <div class="col-lg-8">
                        <h4>Connect to Zeegens Directly</h4>
                        <p style={{ opacity: '80%' }}>Connect to us directly via WhatsApp and let us start building your Website.
                        </p>
                    </div>
                    <div class="col-lg-4">
                        <a style={{ textDecoration: 'none' }} href="https://api.whatsapp.com/send?phone=+919770015304&text=Hii%20Zeegens%20Technologies!"
                            target="_blank">
                            <div class="order_whatsapp"
                                style={{ marginTop: 5, marginBottom: 20, padding: 11, textAlign: 'center', background: '#1EA651', height: 50, color: 'white' }}>
                                <img src="https://cdn.shopify.com/s/files/1/0073/2522/0979/files/
whatsapp-icon.png?v=1615793565" width="25px" style={{ verticalAlign: 'middle' }} />
                                Connect On <span style={{ fontWeight: 600 }}>WhatsApp</span>
                            </div>
                        </a>
                    </div>
                    <hr class="hrFooter" />
                </div>
                <div class="row footerSec-3">
                    <div class="col-lg-3">
                        <p style={{ opacity: '70%', fontSize: 15 }}>Where creativity meets code.</p>
                        <br />
                        <p style={{ opacity: '70%', margin: 0, fontSize: 15 }}><i
                            class="fa-solid fa-envelope footerConatctIcons"></i>anas@zeegens.com</p>
                        <p style={{ opacity: '70%', margin: 0, fontSize: 15 }}><i
                            class="fa-solid fa-phone footerConatctIcons"></i>+91 9770015304</p>
                    </div>
                    <div class="col-lg-9">
                        <p style={{ opacity: '70%', margin: 0, fontSize: 15 }}>©2024 Zeegens Technologies. All rights
                            reserved.<br /><br />

                            Zeegens Technologies is a company providing cutting-edge Web Development solutions to
                            Businesses.<br /><br />

                            We take pride in our services designed to elevate your online presence and drive business growth.
                            Zeegens Technologies offers a comprehensive suite of Web Development services including E-commerce, SaaS,
                            Business, Custom Web and App, WordPRess, Shopify, Course websites etc that meets your unique business needs.
                            <br /><br />

                            {/* Please note that Zeegens Technologies is not a law firm, and any information provided on our website or
                            through
                            communication channels should not be considered official legal advice. Our content is intended for
                            general and educational purposes only. By utilizing our services and accessing our website, you
                            explicitly agree to adhere to our Terms of
                            Service, Privacy Policy, and Data Processing Addendum. <br /><br /> */}

                            At Zeegens Technologies, we are dedicated to helping your brand thrive in the digital landscape. Your success
                            is
                            our priority, and we look forward to partnering with you in Web Development on your journey to
                            success.

                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
