import React from 'react';

const servicesData = [
    {
        title: "Web App Development",
        description: `Build robust and scalable web applications tailored to meet your business needs. Our team specializes in creating responsive, user-friendly, and secure web apps designed to deliver seamless performance across all devices.`,
    },
    {
        title: "Mobile App Development",
        description: `Transform your ideas into dynamic mobile apps for iOS and Android. We craft custom mobile solutions with intuitive designs and cutting-edge technologies to ensure the best user experience and functionality.`,
    },
    {
        title: "E-commerce Website",
        description: `Launch a feature-rich e-commerce platform to grow your online business. From seamless payment integration to a visually stunning storefront, we deliver websites designed to boost your sales and enhance customer engagement.`,
    },
    {
        title: "Business Websites",
        description: `Establish your online presence with professional business websites. Our team creates sleek, modern, and informative websites that reflect your brand identity and communicate your value to clients effectively.`,
    },
    {
        title: "Custom App Solutions",
        description: `Need a unique app tailored to your specific requirements? We specialize in delivering custom solutions that align perfectly with your business goals and bring your vision to life with precision and innovation.`,
    },
    {
        title: "WordPress & Shopify",
        description: `Leverage the power of WordPress and Shopify to create stunning and functional websites. Whether it’s a blog, portfolio, or online store, we build platforms optimized for performance and scalability.`,
    },
];

const Services = () => {
    return (
        <>
            <section className="row featuresSec" id="services" style={{ opacity: '100%', color: 'white' }}>
                <div className="row">
                    <center>
                        <h2 className="csHeading">Our Services: Your One-Stop Tech Solutions</h2>
                    </center>
                </div>
                {servicesData.map((service, index) => (
                    <div className="col-lg-4" key={index} style={{ margin: '3% 0' }}>
                        <div className="subFeaturesDiv">
                            <h4>{service.title}</h4>
                            <p style={{ opacity: '70%', margin: 0 }}>{service.description}</p>
                        </div>
                    </div>
                ))}
            </section>
        </>
    );
}

export default Services;