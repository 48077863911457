import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Website/pages/Home";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Thankyou from "./Website/pages/Thankyou";


function App() {
  return (
    <div className="App" style={{ background: 'black' }}>
      <Router>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<Thankyou />} path="/thank-you" />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
