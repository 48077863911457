import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormHelperText,
    useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import emailjs from 'emailjs-com';
import { useNavigate } from "react-router-dom";
import { IoCalendarClearOutline } from "react-icons/io5";

export default function EmailForm() {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        requirement: "",
    });

    const [errors, setErrors] = useState({
        name: false,
        email: false,
        phone: false,
        requirement: false,
    });

    const styles = {
        container: {
            background: "#18181F",
            padding: "4%",
            margin: 0,
            borderRadius: "10px",
        },
        button: {
            background: "#93f059",
            padding: isDesktop ? "8%" : "5%",
            width: isDesktop ? '' : 200,
            color: "black",
            borderRadius: "5px",
            marginTop: isDesktop ? '' : '15%'
        },
        textField: {
            "& .MuiInputLabel-root": { color: "#93f059" },
            "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#93f059" },
                "&:hover fieldset": { borderColor: "#93f059" },
                "&.Mui-focused fieldset": { borderColor: "#93f059" },
            },
            input: { color: "white" },
            marginTop: isDesktop ? '' : '5%',
            paddingRight: isDesktop ? '5%' : '',
        },
        select: {
            "& .MuiInputLabel-root": { color: "#93f059" },
            "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#93f059" },
                "&:hover fieldset": { borderColor: "#93f059" },
                "&.Mui-focused fieldset": { borderColor: "#93f059" },
            },
            "& .MuiSelect-select": { color: "white" },
            marginTop: isDesktop ? '' : '5%',
            paddingRight: isDesktop ? '5%' : ''
        },
        meetingBox: {
            borderRadius: 5,
            width: 250,
            margin: 'auto'
        },
        meetingBtn: {
            background: '#93f059',
            color: 'black',
            marginTop: '3%',
            fontWeight: 600,
            padding: '6% 9%'
        }
    };

    const handleChange = (field) => (event) => {
        const value = event.target.value;
        setFormData((prev) => ({ ...prev, [field]: value }));
        setErrors((prev) => ({ ...prev, [field]: false })); // Reset error for the field
    };

    const validateForm = () => {
        const newErrors = {
            name: !formData.name.trim(),
            email: !formData.email.trim(),
            // email: !formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email),
            // phone: !formData.phone.trim() || !/^\d{10}$/.test(formData.phone),
            phone: !formData.phone.trim(),
            requirement: !formData.requirement.trim(),
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some((error) => error); // Check if there are no errors
    };

    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        if (validateForm()) {
            setLoading(true)
            emailjs.send(
                'service_nutjpxv',
                'template_63w75ff',
                formData,
                'm6-Zjo4I2uIKNv_mk'
            ).then((result) => {
                setLoading(false);
                navigate('/thank-you')
            }, (error) => {
                setLoading(false);
            });
        }
    };

    return (
        <>
            <div style={{ background: "black", padding: "0 7% 6%" }} id="contact">

                <Grid container spacing={3} style={styles.container}>
                    <Grid item md={2.5} style={{ width: '100%', padding: 0 }}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            sx={styles.textField}
                            value={formData.name}
                            onChange={handleChange("name")}
                            error={errors.name}
                            helperText={errors.name && "Name is required"}
                        />
                    </Grid>
                    <Grid item md={2.5} style={{ width: '100%', padding: 0 }}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            sx={styles.textField}
                            value={formData.email}
                            onChange={handleChange("email")}
                            error={errors.email}
                            helperText={errors.email && "Enter a valid email address"}
                        />
                    </Grid>
                    <Grid item md={2.5} style={{ width: '100%', padding: 0 }}>
                        <TextField
                            label="Phone"
                            variant="outlined"
                            fullWidth
                            sx={styles.textField}
                            value={formData.phone}
                            onChange={handleChange("phone")}
                            error={errors.phone}
                            helperText={
                                errors.phone && "Enter a valid 10-digit phone number"
                            }
                        />
                    </Grid>
                    <Grid item md={2.5} style={{ width: '100%', padding: 0 }}>
                        <FormControl
                            fullWidth
                            sx={styles.select}
                            error={errors.requirement}
                        >
                            <InputLabel id="demo-simple-select-label">Requirement</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                value={formData.requirement}
                                onChange={handleChange("requirement")}
                            >
                                <MenuItem value={"Website"}>Website</MenuItem>
                                <MenuItem value={"Web App"}>Web App</MenuItem>
                                <MenuItem value={"Mobile App"}>Mobile App</MenuItem>
                                <MenuItem value={"Web App + Mobile App"}>
                                    Web App + Mobile App
                                </MenuItem>
                            </Select>
                            {errors.requirement && (
                                <FormHelperText>Requirement is required</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item md={2} style={{ padding: 0 }}>
                        <Button
                            disabled={loading}
                            variant="contained"
                            fullWidth
                            style={styles.button}
                            onClick={handleSubmit}
                        >
                            {loading ? 'Request A Quote...' : 'Request A Quote'}
                        </Button>
                    </Grid>
                </Grid>

                <p style={{ opacity: '80%', color: 'white', textAlign: 'center', marginTop: '1%', fontStyle: 'italic' }}>
                    "Or Directly Schedule a 30-minute Meeting with our Team Experts."
                </p>

                <div style={styles.meetingBox}>
                    <center>
                        <a href="https://calendly.com/zeegens-technologies/zeegens-technologies" target="_blank" >
                            <Button
                                style={styles.meetingBtn}
                                variant="contained" startIcon={<IoCalendarClearOutline />}>
                                Schedule a Meeting
                            </Button>
                        </a>
                    </center>
                </div>
            </div>
        </>
    );
}