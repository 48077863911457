import HeroSection from "../components/HeroSection";
import Process from "../components/Process";
import WhyChooseUs from "../components/WhyChooseUs";
import Testimonials from "../components/Testimonials";
import Services from "../components/Services";
import CTA from "../components/CTA";
import Footer from "../components/Footer";
import Header from "../components/Header";
import EmailForm from "../components/EmailForm";

export default function Home() {
    return (
        <>
            <Header />
            <HeroSection />
            <EmailForm />
            <Services />
            <Testimonials />
            <WhyChooseUs />
            <Process />
            <CTA />
            <Footer />
        </>
    )
}